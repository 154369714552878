<template>
	<div class="groupManage">
		<div class="notice">
			<p></p><!-- https://static.tuanhuo.howtec.cc/miniprogram/common/wechatHelper.zip -->
			<p>点此下载<a href="#" @click="pwdVisible = true" style="font-weight: bold;color: #fc7414;">微信个人号助手
					最近更新：2024-09-29</a>，配置时请正确填入“昵称”或“别名”，系统将同时匹配“昵称”或“别名”，命中后触发相关功能。
			</p>
			<p>1、如未配置自动回复的好友，则向所有好友开启自动回复，否则仅向已配置好友回复；自动接收信息同理。</p>
			<p>2、仅向已配置自动回复的的群组回复信息，否则不回复信息；自动接收信息同理。</p>
			<p style="font-weight: bold;">3、使用@开头的聊天内容不会触发自动回复</p>
			<p style="font-weight: bold;">4、建议将需要自动运营的社群保存至微信的通讯录</p>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item">
					<div class="item-label">自动回复:</div>
					<div class="item-input">
						<el-select v-model="searchForm.is_reply" placeholder="请选择是否自动回复" style="width: 100%;">
							<el-option v-for="item in replyList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">自动接收:</div>
					<div class="item-input">
						<el-select v-model="searchForm.is_receive" placeholder="请选择是否自动接收" style="width: 100%;">
							<el-option v-for="item in receiveList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click="page = 1, loading = true, getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click="dialogVisible = true, dialogType = 1, form = {
                    is_reply: 1,is_receive: 1
                }">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="名称" prop="nickname" align="center"></el-table-column>
				<el-table-column label="类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">好友</span>
						<span v-if="scope.row.type == 2">群组</span>
					</template>
				</el-table-column>
				<el-table-column label="自动回复" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.is_reply == 1">开启</span>
						<span v-if="scope.row.is_reply == 2">关闭</span>
					</template>
				</el-table-column>
				<el-table-column label="自动接收" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.is_receive == 1">开启</span>
						<span v-if="scope.row.is_receive == 2">关闭</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="名称" required>
					<el-input v-model="form.nickname" placeholder="请输入名称"></el-input>
				</el-form-item>
				<el-form-item label="类型" required>
					<el-select v-model="form.type" placeholder="请选择类型" style="width: 100%;">
						<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="自动回复" required>
					<el-radio-group v-model="form.is_reply">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="自动接收" required>
					<el-radio-group v-model="form.is_receive">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="密码" :visible.sync="pwdVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="formDown" :model="formDown" label-width="80px">
				<el-form-item label="登录密码" required>
					<el-input type="password" v-model="formDown.password" placeholder="请输入登录密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" required>
					<el-input type="password" v-model="formDown.password_confirmation" placeholder="请确认密码"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="pwdVisible = false">取 消</el-button>
				<el-button type="warning" @click="confirmDown">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchForm: {},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 新增、编辑弹窗
				typeList: [{
						id: 1,
						name: '好友'
					},
					{
						id: 2,
						name: '群组'
					}
				],
				replyList: [{
						id: 1,
						name: '开启'
					},
					{
						id: 2,
						name: '关闭'
					}
				],
				receiveList: [{
						id: 1,
						name: '开启'
					},
					{
						id: 2,
						name: '关闭'
					}
				],
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				pwdVisible: false,
				form: {
					is_reply: 1,
					is_receive: 1
				},
				formDown: {
					password: "",
					repassword: ""
				},
				uid: ""
			}
		},
		mounted() {
			this.getList()
			let member = JSON.parse(sessionStorage.getItem("member"))
			this.uid = member.id
			this.formDown.id = member.id
		},
		methods: {
			confirmDown() {
				this.$http.post(`/erp/v1/wx_groups/download`, {
					...this.formDown
				}).then((res) => {
					this.pwdVisible = false
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data)
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.$http.post(`/erp/v1/wx_groups/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 编辑弹窗
			openDialog(item) {
				this.form = {
					...item
				}
			},
			// 确定
			confirmFn() {
				if (!this.form.nickname) {
					this.$message.error('请输入名称');
					return
				}
				if (!this.form.type) {
					this.$message.error('请输入负责人');
					return
				}
				let url = this.dialogType == 1 ? '/erp/v1/wx_groups/add' : '/erp/v1/wx_groups/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/wx_groups/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
	}
</script>

<style lang="scss" scoped>
	.groupManage {
		.notice {
			background: #fff;
			margin-bottom: 20px;
			padding: 20px;
			border-radius: 10px;
		}
	}
</style>